import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

class Briefings extends Controller {
  connect() {
    if (!this.element.querySelector(location.hash)) {
      location.hash = location.hash.split("--")[0]
    }
  }
}

class Briefing extends Controller {
  static targets = ["section", "visibleSection"]
  static intersectionRootMargin = "-80px 0px -200px 0px"
  intersectionObserver = null

  connect() {
    if (this.hasSectionTarget) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => entries.forEach((entry) => this.intersect(entry)),
        { rootMargin: Briefing.intersectionRootMargin },
      )
      this.sectionTargets.forEach((section) =>
        this.intersectionObserver.observe(section),
      )
    }
  }

  intersect(entry) {
    if (entry.isIntersecting) {
      entry.target.dataset.briefingTarget = "section visibleSection"
    } else {
      entry.target.dataset.briefingTarget = "section"
    }
    this.dispatch("section-change")
  }
}

class BriefingToc extends Controller {
  static targets = []
  static outlets = ["briefing"]
  static throttles = ["refreshToc"]
  static classCurrent = "-current"

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the toc too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshToc(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshToc())
  }

  resetToc() {
    this.element
      .querySelectorAll(`.${BriefingToc.classCurrent}`)
      .forEach((element) => {
        element.classList.remove(BriefingToc.classCurrent)
        element.removeAttribute("aria-current")
        element.parentElement.removeAttribute("aria-expanded")
      })
  }

  refreshToc() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id,
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)

          this.resetToc()
          matchingTocLink.classList.add(BriefingToc.classCurrent)
          matchingTocLink.parentElement.classList.add(BriefingToc.classCurrent)
          matchingTocLink.setAttribute("aria-current", "true")
          matchingTocLink.parentElement.setAttribute("aria-expanded", "true")
          history.replaceState(null, "", location.pathname + "#" + matchingTocLink.getAttribute("href").slice(1))

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
    history.replaceState(null, "", location.pathname + "#" + anchorName)
  }
}

class BriefingHeader extends Controller {
  static targets = ["editionName"]
  static outlets = ["briefing"]
  static throttles = ["refreshHeader", "toggleVisibility"]
  static values = {
    isSticky: Boolean,
  }

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the header too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshHeader(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshHeader())
    if (this.isStickyValue) {
      this.toggleVisibility()
      window.addEventListener("scroll", () => this.toggleVisibility())
    }
  }

  toggleVisibility() {
    this.element.classList.toggle("-hidden", window.scrollY < 10)
  }

  refreshHeader() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id.split("--")[0],
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)
          this.editionNameTarget.textContent = matchingTocLink.textContent

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}

class BriefingDatepicker extends Controller {
  showPicker(event) {
    try {
      event.target.showPicker()
    } catch { }
  }

  updateDate(event) {
    event.target.form.submit()
  }
}

class BriefitemToolbar extends Controller {
  static targets = ["mailto"]
  static classHidden = "-hidden"
  static values = {
    briefitemId: Number,
  }

  animate({ detail: { currentTarget } }) {
    const icons = currentTarget.querySelectorAll("svg")
    const hiddenIcon = Array.from(icons).find(icon => icon.classList.contains(BriefitemToolbar.classHidden))
    const visibleIcon = Array.from(icons).find(icon => !icon.classList.contains(BriefitemToolbar.classHidden))

    hiddenIcon.classList.remove(BriefitemToolbar.classHidden)
    visibleIcon.classList.add(BriefitemToolbar.classHidden)
    setTimeout(() => {
      hiddenIcon.classList.add(BriefitemToolbar.classHidden)
      visibleIcon.classList.remove(BriefitemToolbar.classHidden)
    }, 3000)
  }

  async _fetch(feature) {
    const url = window.location.href.split("#")[0]
    const response = await fetch(
      `${url}?briefitem_id=${this.briefitemIdValue}&feature=${feature}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )

    if (!response.ok) {
      throw new Error(`Could not fetch ${feature}: status ${response.status}`)
    }

    return response.json()
  }

  _openMailto(data) {
    this.mailtoTarget.href = data.mailto
    this.mailtoTarget.click()
    this.mailtoTarget.removeAttribute("data-action")
  }

  _fillDataset(target, text) {
    target.dataset.clipboardDataText = text
  }

  _dispatch(target) {
    this.dispatch("generate", {
      detail: {
        sourceMethod: target.dataset.clipboardSourceMethodParam,
        confirmation: target.dataset.clipboardConfirmationParam,
        currentTarget: target,
      },
    })
  }

  async generate(e) {
    e.preventDefault()
    const target = e.currentTarget
    const feature = e.params.feature

    try {
      const data = await this._fetch(feature)

      if (data.error) {
        throw new Error(data.error)
      }

      if (feature === "copy" || feature === "copy_link") {
        const text = feature === "copy" ? data.copy : data.link
        this._fillDataset(target, text)
        this._dispatch(target)
      }
      if (feature === "mailto") {
        this._openMailto(data)
      }
    } catch (error) {
      console.error("Could not generate briefitem", error)
    }
  }
}

export { Briefing, BriefingDatepicker, BriefingHeader, Briefings, BriefingToc, BriefitemToolbar }
